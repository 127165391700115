export default {
  async update (Vue, id, formData) {
    await Vue.$api.call(
      'sistemaTviacomunicacion.update',
      {
        values: {
          idsistema_tviacomunicacion: id,
          idtviacomunicacion: formData.idtviacomunicacion,
          numero: formData.numero,
          n_serie: formData.n_serie,
          nabonado: formData.nabonado,
          idttransmisor: formData.idttransmisor,
          fcomprobacion: formData.fcomprobacion,
          observaciones: formData.observaciones,
        },
      }
    )
  },
}
